<script>
	import LogoLockup from "$lib/components/LogoLockup.svelte";
	import CTAButton from "$lib/components/CTAButton.svelte";
	import { scrollTo, browseTo } from '$lib/utils/navutils.js';

	export let appURL = 'https://app.shape.exchange';
</script>

<header class="flex justify-between px-8 lg:px-16 py-4 text-sm border-b-8 border-brand-blue">
	<nav class="flex items-center space-x-12">
		<div class="flex space-x-4">
			<a href="/">
				<LogoLockup />
			</a>
		</div>
	</nav>

	<div class="h-20 flex items-center space-x-7">
		<div class="hidden md:flex space-x-8">
			<div><a href="mailto:info@diagraphics.com">Contact</a></div>
		</div>
		<button class="h-10 btn btn-open" title="Sign in" on:click={() => browseTo(appURL)}>Sign&nbsp;in</button>
		<div class="h-10"><CTAButton /></div>
	</div>
</header>

<style>
</style>
