<script>
    import srcLogo from "$lib/assets/logo.svg?url";
    import srcLogoLight from "$lib/assets/logo-light.svg?url";

    import srcWordmark from "$lib/assets/wordmark.svg?url";
    import srcWordmarkLight from "$lib/assets/wordmark-light.svg?url";


    export let light = false;

    $: logo = light ? srcLogoLight : srcLogo;
    $: wordmark = light ? srcWordmarkLight : srcWordmark;

</script>

<div class="flex space-x-4">
    <img src={logo} alt="Shape Exchange Logo" class="h-20" />
    <img
        src={wordmark}
        alt="Shape Exchange Wordmark"
        class="h-20 hidden md:block"
    />
</div>