<script>
	import Header from '$lib/components/Header.svelte';
	import Footer from '$lib/components/Footer.svelte';
</script>

<Header />

<main>
	<slot />
</main>

<Footer />

<style>
	main {
		flex: 1;
		display: flex;
		flex-direction: column;
		/* padding: 1rem 0 1rem 0; */
		width: 100%;
		/* margin: 0 auto; */
		box-sizing: border-box;
	}
</style>
